import React, { useState } from "react"
import "./agenda.css"
import iphoneAgenda from "../../css/vendors/images/iphone-agenda.png"

const AnimIphoneAgenda = (props: { inViewport: boolean }) => {
  const { inViewport, forwardedRef, enterCount } = props
  const [isLoading, setIsLoading] = useState(true)
  let containerAnim = inViewport ? "animContainerIphoneAgenda" : "containerIphoneAgenda"
  let containerViewport = "displayNone" 
  if (enterCount >= 2) {
    containerAnim = "containerIphoneAgenda"
  }

  if (enterCount === 0){
    containerAnim = "displayNone"
  }
  if (!isLoading) {
    containerViewport = "viewport-block-agenda"
  }

  return (
    <div className={containerViewport} ref={forwardedRef}>
      <div className={containerAnim}>
        <img className="iphoneAgenda" src={iphoneAgenda} alt="iphone" onLoad={() => setIsLoading(false)} />
      </div>
    </div>
  )
}

export default AnimIphoneAgenda
