import React from "react"
import "./discover.css"
import AnimIphoneDiscover from './animDiscover.jsx'
import handleViewport from 'react-in-viewport';

const IphoneDiscover = handleViewport(AnimIphoneDiscover);


const Discover = () => {
  return (
    <>
      <div className="containerDiscover">
        <IphoneDiscover />
        <div className="containerText">
          <p className="textDiscover">Découvrez des pépites culturelles en toute simplicité.</p>
          <p className="textMedium">Films, séries, musique... et bientôt podcasts et livres !</p>
          <p className="textReco">Vos recommandations sont générées par notre algorithme, à partir de vos goûts et des profils culturels les plus similaires.</p>
        </div>
      </div>
    </>
  )
}

export default Discover
