import React from "react"
import "../LastPage/LastPage.jsx"
import pictureBpi from "../../css/vendors/images/bpi.png"
import pictureParis from "../../css/vendors/images/paris.png"
import pictureArte from "../../css/vendors/images/arte.png"
import pictureCanal from "../../css/vendors/images/canal.png"
import pictureSalto from "../../css/vendors/images/salto.png"
import pictureCnc from "../../css/vendors/images/CNC_new.png"
import pictureMinistre from "../../css/vendors/images/ministère.png"
import pictureBelleDeMai from "../../css/vendors/images/belledemai.png"
import pictureNicolas from "../../css/vendors/images/nicolas.png"
import pictureCineTek from "../../css/vendors/images/cinetek-remove.png"
import pictureShadowz from "../../css/vendors/images/shadowz.png"
import pictureECinema from "../../css/vendors/images/ecinema.png"


const LastPage = () => {
  return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F5F0E8", paddingTop: "50px" }}>
        <p className="textTrust">Ils nous font confiance.</p>
        <div className="containerSlider">
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img className="pictureTrust" src={pictureBpi} alt="bpi"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureParis} alt="paris"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureArte} alt="arte"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCanal} alt="canal" />
              </div>
              <div className="slide">
                <img className="pictureTrustMin" src={pictureSalto} alt="salto"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCnc} alt="cnc"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureMinistre} alt="Ministère"/>
              </div>
              <div className="slide">
                <img className="pictureTrustBelle" src={pictureBelleDeMai} alt="Belle de mai"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureNicolas} alt="nicolas"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCineTek} alt="cinetek"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureShadowz} alt="shadowz"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureECinema} alt="e-cinema"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureBpi} alt="bpi"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureParis} alt="paris"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureArte} alt="arte"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCanal} alt="canal"/>
              </div>
              <div className="slide">
                <img className="pictureTrustMin" src={pictureSalto} alt="salto"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCnc} alt="cnc"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureMinistre} alt="ministère"/>
              </div>
              <div className="slide">
                <img className="pictureTrustBelle" src={pictureBelleDeMai} alt="Belle de mai"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureNicolas} alt="nicolas"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureCineTek} alt="cinetek"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureShadowz} alt="shadowz"/>
              </div>
              <div className="slide">
                <img className="pictureTrust" src={pictureECinema} alt="e-cinema"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default LastPage
