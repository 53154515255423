import React,{useLayoutEffect} from "react"
import "../css/style.css"
import "./Cgu.css"
import logo from "../css/vendors/images/logoWhite.png"
import "../css/fonts/primary_font.css"
import { Link } from "react-router-dom"
import test from "../css/vendors/images/tastr-image.png"

const Cgu = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="contentCgu">
      <div style={{ display: "none" }}>
        <img src={test} alt="test" />
      </div>
      <div className="header">
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
      </div>
      <div className="allTextCgu">
        <p className="title">Conditions générales d'utilisation</p>
        <p className="subtitle">En vigueur au 01/01/2020</p>

        <p className="textCgu">
          Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par tastr.us et de définir
          les conditions d’accès et d’utilisation des services par « l'Utilisateur ». Les présentes CGU sont accessibles sur le site à la rubrique «CGU». Toute inscription ou utilisation du site
          implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte
          expressément les présentes CGU. En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. tastr.us
          se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
        </p>
        <p className="textCgu">
          <span className="subtitle">ARTICLE 1 : Les mentions légales</span>
        </p>
        <p className="textCgu">
          Le Site est hébergé par OVH SAS une filiale de la société OVH Groupe SAS, société immatriculée au RCS de Lille sous le numéro 537 407. Siège social : 2 rue Kellermann - 59100 Roubaix -
          France.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 2 : Accès au site</span>
        </p>
        <p className="textCgu">Le site tastr.us permet à l'Utilisateur un accès gratuit aux services suivants :</p>

        <p className="textCgu">
          Notation de films, séries et albums de musique Possibilité de commenter pour un film, une série ou un album de musique Possibilité d'ajouter films, séries et albums à la section favoris
          prévue à cet effet Consultation des notes et commentaires des autres utilisateurs Configuration du profil Réception d’alertes et de newsletters par email
        </p>

        <p className="textCgu">
          Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique,
          logiciels, connexion Internet, etc.) sont à sa charge. L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant
          de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.
          Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués après son inscription. Tout Utilisateur
          membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable. Tout
          événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage
          pas la responsabilité de tastr.us. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis. L'Utilisateur
          a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 3 : Collecte des données</span>
        </p>
        <p className="textCgu">
          Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés. Dans le cadre de son activité tastr.us collecte les données relatives aux Utilisateurs suivantes : notes œuvres, commentaires œuvres, adresse
          email. Plus spécifiquement dans le cadre d’une inscription via facebook : photo de profil et amis facebook. En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978,
          l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit par mail à l'adresse mail
          contact@tastr.us
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 4 : Propriété intellectuelle</span>
        </p>
        <p className="textCgu">
          Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le
          droit d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus
          du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite. Toute représentation totale ou partielle de ce site par quelque
          procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété
          intellectuelle. Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et
          sa source.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 5 : Responsabilité</span>
        </p>
        <p className="textCgu">
          Les sources des informations diffusées sur le site tastr.us sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations
          communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site tastr.us ne peut être tenu responsable de la modification des
          dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue
          dans ce site. L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation
          de son identifiant et mot de passe. Le site décline toute responsabilité. Le site tastr.us ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout
          matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site. La responsabilité du site ne peut être engagée en cas de force majeure
          ou du fait imprévisible et insurmontable d'un tiers.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 6 : Liens hypertextes</span>
        </p>
        <p className="textCgu">
          Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site tastr.us. Ce dernier n’a pas de contrôle sur les pages web
          sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 7 : Cookies</span>
        </p>
        <p className="textCgu">
          L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Les cookies sont de petits fichiers stockés
          temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site tastr.us. Les cookies ne contiennent pas d’information
          personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de
          la visite de l’Utilisateur, d’autres restent. L’information contenue dans les cookies est utilisée pour améliorer le site tastr.us. En naviguant sur le site, L’Utilisateur les accepte.
          L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies. A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages
          risquent de lui être refusées. L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
        </p>
        <p className="textCgu">
          <span className="subtitle">ARTICLE 8 : Publication par l’Utilisateur</span>
        </p>
        <p className="textCgu">
          Le site permet aux membres de publier les contenus suivants : Possibilité de rédiger un commentaire sur œuvre. Dans ses publications, le membre s’engage à respecter les règles de la
          Netiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur. Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en
          ligne, sans avoir à s’en justifier auprès du membre. Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il
          cède à la société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans
          le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit d'utiliser sa publication sur internet et sur les
          réseaux de téléphonie mobile. La société éditrice s'engage à faire figurer le nom du membre à proximité de chaque utilisation de sa publication. Tout contenu mis en ligne par l'Utilisateur
          est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un
          tiers lésé contre le site sera pris en charge par l'Utilisateur. Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans
          préavis.
        </p>

        <p className="textCgu">
          <span className="subtitle">ARTICLE 9 : Droit applicable et juridiction compétente</span>
        </p>
        <p className="textCgu">
          La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en
          connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1. Conformément à la loi Informatique et
          libertés du 6 janvier 1978 modifiée et au règlement européen général sur la protection des données à caractère personnel (UE) 2016/679 du 27 avril 2016, vous disposez d’un droit d’accès et
          d’un droit de rectification des données vous concernant et, s’ils sont applicables, d’un droit de suppression, d’un droit d’opposition au traitement de vos données, d’un droit à l’effacement
          et à la limitation du traitement ainsi que d’un droit à la portabilité de vos données. Vous disposez également du droit de définir des directives relatives au sort de vos données après votre
          décès. Vous pouvez exercer vos droits, à tout moment, en effectuant une demande écrite accompagnée d’un justificatif d’identité par voie électronique à l’adresse contact@tastr.us
        </p>


        <p className="textCgu">
          <span className="subtitle">ARTICLE 10 : Effacement des données</span>
        </p>
        <p className="textCgu">
        Tout utilisateur peut à tout moment demander l’effacement complet et définitif de l’ensemble de ses données personnelles sur la plateforme en contactant directement : <a href="mailto:contact@tastr.us">contact@tastr.us</a>   </p>

      </div>
    </div>
  )
}

export default Cgu
