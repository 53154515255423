import React from "react"
import ReactDOM from "react-dom"
import "./css/style.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Cgu from "./Cgu/Cgu"
import Footer from "../src/Components/Footer/Footer.jsx"
import App from "./App/App"

const Routing = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/cgu" component={Cgu} />
      </Switch>
      <Footer />
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById("root")
)
