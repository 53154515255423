import React from "react"
import imgPictureTastrTwo from "../../css/vendors/images/pictureTastr2.png"
import "./pictureTastrTwo.css"

const PictureTastr = () => {
  return (
    <>
      <img className="pictureTastr" src={imgPictureTastrTwo} alt="iphone" />
    </>
  )
}

export default PictureTastr
