import React from "react"
import "./lastpage.css"
import heart from "../../css/vendors/images/heart.png"
import pictureLastPage from "../../css/vendors/images/picture-last-page.png"
import handleViewport from "react-in-viewport"

import AnimIphoneLastPage from "./animLastPage.jsx"

const IphoneLastPage = handleViewport(AnimIphoneLastPage)

const LastPage = () => {
  return (
    <div className="lastpage">
      <div className="containerTop">
        <IphoneLastPage />
        <div className="containerTopLastPage">
          <p className="titleLastPage">Rejoignez la communauté tastr !</p>
          <div>
            <p className="textLastPage">Comparer vos goûts avec ceux de vos amis, découvrez leurs recommandations.</p>
          </div>
          <div>
            <div className="containerTextHeart">
              <p className="textHeart">Partagez vos coups de cœurs avec la communauté tastr.</p>
              <img className="heart" src={heart} alt="heart"/>
            </div>
          </div>
          <div className="containerPicture">
            <img className="pictureLastPage" src={pictureLastPage} alt="tastr"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LastPage
