import React, { useState } from "react"
import "./lastpage.css"
import iphoneLastPage from "../../css/vendors/images/iphone-lastpage.png"

const AnimIphoneLastPage = (props: { inViewport: boolean }) => {
  const { inViewport, forwardedRef, enterCount } = props
  const [isLoading, setIsLoading] = useState(true)

  let containerAnim = inViewport ? "animContainerIphoneLastPage" : "containerIphoneLastPage"
  let containerViewport = "displayNone"
  if (enterCount >= 2) {
    containerAnim = "containerIphoneLastPage"
  }
  if (enterCount === 0) {
    containerAnim = "displayNone"
  }
  if (!isLoading) {
    containerViewport = "viewport-block"
  }

  return (
    <div className={containerViewport} ref={forwardedRef}>
      <div className={containerAnim}>
        <img className="iphoneLastPage" src={iphoneLastPage} alt="iphone" onLoad={() => setIsLoading(false)} />
      </div>
    </div>
  )
}

export default AnimIphoneLastPage
