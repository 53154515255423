import React from "react"
import "../css/style.css"
import "./App.css"
import Home from "../Components/Home/Home.jsx"
import PictureTastr from "../Components/PictureTastr/PictureTastr.jsx"
import Recommandation from "../Components/Recommandation/Recommandation.jsx"
import Discover from "../Components/Discover/Discover.jsx"
import PictureTastrTwo from "../Components/PictureTastrTwo/PictureTastrTwo.jsx"
import Agenda from "../Components/Agenda/Agenda.jsx"
import LastPage from "../Components/LastPage/LastPage.jsx"
import Trust from "../Components/Trust/Trust.jsx"

const App = () => {
  return (
    <div className="App">
      <div style={{ position: "relative"}}>
        <Home />
        <PictureTastr />
      </div>
      <Recommandation />
      <div style={{ position: "relative" }}>
        <Discover />
        <PictureTastrTwo />
      </div>
      <Agenda />
      <LastPage />
      <Trust />
    </div>
  )
}

export default App
