import React, { useState } from "react"
import "./discover.css"
import imgIphoneDiscover from "../../css/vendors/images/iphone-discover.png"

const AnimIphoneDiscover = (props: { inViewport: boolean }) => {
  const { inViewport, forwardedRef, enterCount } = props
  const [isLoading, setIsLoading] = useState(true)

  let containerAnim = inViewport ? "animContainerIphoneDiscover" : "containerIphoneDiscover"
  let containerViewport = "displayNone" 
  if (enterCount >= 2) {
    containerAnim = "containerIphoneDiscover"
  }

  if (enterCount === 0){
    containerAnim = "displayNone"
  }
  if (!isLoading) {
    containerViewport = "viewport-block"
  }

  return (
    <div className={containerViewport} ref={forwardedRef}>
      <div className={containerAnim}>
        <img className="imgIphoneDiscover" src={imgIphoneDiscover} alt="iphone" onLoad={() => setIsLoading(false)}  />
      </div>
    </div>
  )
}

export default AnimIphoneDiscover
