import React from "react"
import logo from "../../css/vendors/images/logo.png"
import imgAppStore from "../../css/vendors/images/app-store.png"
import imgGoogleStore from "../../css/vendors/images/google-store.png"
import handleViewport from "react-in-viewport"
import "./home.css"
import { AnimIphoneOne, AnimIphoneTwo } from "./animHome.jsx"

const IphoneOne = handleViewport(AnimIphoneOne)
const IphoneTwo = handleViewport(AnimIphoneTwo)

const Home = () => {
  return (
    <>
      <div className="containerHome">
        <div className="containerLeft">
          <div className="hello">
            <div className="containerLeftLogo">
              <img className="logo" src={logo} alt="logo"/>
            </div>
            <p className="textDiscoverHome">Découvrez instantanément les films et les séries qui vous correspondent, sur vos plateformes de VOD.</p>
          </div>
          <div className="containerLeftText">
            <div className="containerStore">
              <a href="https://apps.apple.com/fr/app/tastr/id1583223521"><img className="imgAppStore" src={imgAppStore} alt="app store"/></a>
              <a href="https://play.google.com/store/apps/details?id=com.tastr.tastrApp"><img className="imgGoogleStore" src={imgGoogleStore} alt= "google play"/></a>
            </div>
            <p className="textSearch">Moins de temps à chercher, plus de temps à regarder !</p>
          </div>
        </div>
        <div className="containerRight">
          <IphoneOne />
          <IphoneTwo />
        </div>
      </div>
    </>
  )
}

export default Home
