import React from "react"
import "./recommandation.css"
import pictureCardPablo from "../../css/vendors/images/pablo.png"
import pictureCardBrice from "../../css/vendors/images/brice.png"
import pictureCardVero from "../../css/vendors/images/vero.png"
import pictureCardSue from "../../css/vendors/images/sue.png"
import AnimCards from "../CardReco/animCards.jsx"
import handleViewport from 'react-in-viewport';

const Recommandation = () => {

  const pablo = {
    picture: pictureCardPablo,
    name: "Pablo",
    review: "Excellente application facile d’utilisation et très complète, elle permet de découvrir d’excellents films sans passer des heures à chercher."
  }
  const brice = {
    picture: pictureCardBrice,
    name: "Brice",
    review: "Incroyable ! Un petit bijou cette appli, vraiment ❤️ Il faut d’autant plus saluer l’initiative que c’est gratuit et sans pub 😍 Merci infiniment !"
  }
  const vero = {
    picture: pictureCardVero,
    name: "Véronique",
    review: "Excellente application, c’est bluffant. Les recommandations tombent juste. Très utile, je recommande 👏"
  }
  const sue = {
    picture: pictureCardSue,
    name: "Sue",
    review: "Génial! Gain de temps indéniable et excellente sélection."
  }
  const Card1 = handleViewport(AnimCards);
  const Card2 = handleViewport(AnimCards);
  const Card3 = handleViewport(AnimCards);
  const Card4 = handleViewport(AnimCards);

  
  return (
    <>
      <div className="containerRecommandation">
        <h1 className="titleReco">Des recommandations sur mesure !</h1>
        <div className="containerCard">
          <Card1 picture={pablo} />
          <Card2 picture={brice}/>
          <Card3 picture={vero} />
          <Card4 picture={sue} /> 
        </div>
      </div>
    </>
  )
}

export default Recommandation
