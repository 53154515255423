import React from "react"
import "./footer.css"
import noter from "../../css/vendors/images/Noter.png"
import { Link } from "react-router-dom"
import imgAppStore from "../../css/vendors/images/app-store.png"
import imgGoogleStore from "../../css/vendors/images/google-store.png"

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#1c1d22", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="footer">
        <div className="contentReview">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="containerReview">
              <img className="review" src={noter} alt="noter" />
            </div>
          </Link>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="textFooter">
            <Link to="/cgu" style={{ textDecoration: "none", width: "100px", textAlign: "center" }}>
              <p className="text">CGU</p>
            </Link>
            <a href="mailto:contact@tastr.us" style={{ textDecoration: "none", width: "100px", textAlign: "center" }}>
              <p className="text">Contact</p>
            </a>
          </div>
        </div>
        <div className="imgFooter">
          <div className="containerImgFooter">
          <a href="https://apps.apple.com/fr/app/tastr/id1583223521"><img className="imgAppStoreFooter" src={imgAppStore} alt="app store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.tastr.tastrApp"><img className="imgGoogleStoreFooter" src={imgGoogleStore} alt="google play" /></a>
          </div>
        </div>
      </div>
      <p className="textTastr">© tastr - {new Date().getFullYear()} </p>
    </div>
  )
}

export default Footer
