import React from "react"
import imgPictureTastr from "../../css/vendors/images/pictureTastr.png"
import "./pictureTastr.css"

const PictureTastr = () => {
  return (
    <>
      <img className="pictureTastrOne" src={imgPictureTastr} alt="iphone" />
    </>
  )
}

export default PictureTastr
