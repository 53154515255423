import React from "react"
import "./cardReco.css"
import stars from "../../css/vendors/images/stars.png"

const Card = (props: { inViewport: boolean, picture: any }) => {
  const { forwardedRef, enterCount, picture } = props
  
  let containerAnim = "animCardReco"

  if (enterCount === 0) {
    containerAnim = "displayNone"
  }

  if (enterCount >= 2) {
    containerAnim = "animCardReco"
  }


  return (
    <div className="viewport-block" ref={forwardedRef}>
      <div className={containerAnim}>
        <div style={{ flex: 1 }}>
          <div className="containerCardTop">
            <img className="pictureCard" src={picture.picture} alt="profil" />
            <div>
              <p className="pictureName">{picture.name}</p>
              <img src={stars} alt="étoiles" />
            </div>
          </div>
        </div>
        <div style={{ flex: 1, flexGrow: 1 }}>
          <p className="pictureText">{picture.review}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
