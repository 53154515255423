import React from "react"
import "./agenda.css"
import AnimIphoneAgenda from './animAgenda.jsx'
import handleViewport from 'react-in-viewport';

const IphoneAgenda = handleViewport(AnimIphoneAgenda);

const Agenda = (enterCount) => {
  let agenda
  if(enterCount === 0){
    agenda = "agendaDisplayNone"
  }else{
    agenda = "agenda"
  }

  return (
    <div className={agenda}>
      <div className='containerTitle'>
        <p className="titleAgenda">Ne manquez plus l’actualité de vos artistes préférés !</p>
        <p className="titleArtist">Retrouvez les concerts, les sorties d’albums et les sorties cinéma et VOD dans votre agenda culturel personnalisé.</p>
      </div>
      <IphoneAgenda />
    </div>
  )
}

export default Agenda
