import React, { useState } from "react"
import "./home.css"
import iphoneOne from "../../css/vendors/images/iphone-home-1.png"
import iphoneTwo from "../../css/vendors/images/iphone-home-2.png"

const AnimIphoneOne = (props: { inViewport: boolean }) => {
  const { inViewport, forwardedRef, enterCount } = props
  const [isLoading, setIsLoading] = useState(true)

  let containerAnim = inViewport ? "animContainerIphoneOne" : "containerIphoneOne"
  let containerViewport = "displayNone" 
  if (enterCount >= 2) {
    containerAnim = "containerIphoneOne"
  }
  if (enterCount === 0) {
    containerAnim = "displayNone"
  }
  if (!isLoading) {
    containerViewport = "viewport-block"
  }

  return (
    <div className={containerViewport} ref={forwardedRef}>
      <div className={containerAnim}>
        <img className="imgIphoneOne" src={iphoneOne} alt="iphone" onLoad={() => setIsLoading(false)} />
      </div>
    </div>
  )
}

export { AnimIphoneOne }

const AnimIphoneTwo = (props: { inViewport: boolean }) => {
  const { inViewport, forwardedRef, enterCount } = props
  const [isLoading, setIsLoading] = useState(true)

  let containerAnim = inViewport ? "animContainerIphoneTwo" : "containerIphoneTwo"
  let containerViewport = "displayNone" 
  if (enterCount >= 2) {
    containerAnim = "containerIphoneTwo"
  }
  if (enterCount === 0) {
    containerAnim = "displayNone"
  }
  if (!isLoading) {
    containerViewport = "viewport-block"
  }

  return (
    <div className={containerViewport} ref={forwardedRef}>
      <div className={containerAnim}>
        <img className="imgIphoneTwo" src={iphoneTwo} alt="iphone" onLoad={() => setIsLoading(false)} />
      </div>
    </div>
  )
}

export { AnimIphoneTwo }
